import { toPrettyCurrency, AgingReportBalance } from "common";
import "./BalanceCell.scss";
import { StatusIcon } from "./StatusIcon";
import {
  AgingReportBalanceStatusType,
  AgingReportStatusesMap,
} from "../util/getAgingReportStatus";
import _ from "lodash";

export const BalanceCell = ({
  balance,
  statusesMap,
  toggleClaimPaid,
  statusFilters
}: {
  balance: AgingReportBalance;
  statusesMap?: AgingReportStatusesMap;
  toggleClaimPaid: () => {};
  statusFilters: AgingReportBalanceStatusType[]
}) => {
  if (statusFilters?.length) {
    statusesMap = _.pick(statusesMap, statusFilters) as any;
  }

  if (!balance || !statusesMap || !Object.keys(statusesMap).length) return <td />;


  let isPaid = false;
  const statusClasses: string[] = [];
  const statusSymbols = Object.entries(statusesMap).map(([statusType, statusData]) => {
    if (statusType === AgingReportBalanceStatusType.Paid) {
      isPaid = true;
    }
    statusClasses.push(`is-${statusType}`)
    return <StatusIcon statusType={statusType as AgingReportBalanceStatusType} statusData={statusData} />
  }
  );


  return (
    <td className={`balance-cell ${statusClasses.join(' ')} `}>
      <div className="content">
        {balance?.history?.[0] > 0 && (
          <i
            className={`${isPaid ? "fa-regular fa-square-check" : "fa-regular fa-square"
              }  cursor mark-claim-paid `}
            onClick={toggleClaimPaid}
            title="Toggle Paid"
          ></i>
        )}
        &nbsp;
        {toPrettyCurrency(balance?.history?.[0])}
        &nbsp;
        <div className="statuses">{statusSymbols}</div>
      </div>
    </td>
  );
};
