import moment, { Moment } from 'moment';

import { WithTrackingDates } from '../types';

export const withTrackingDates = <T extends WithTrackingDates>(obj: T) => {
  const now = new Date().toISOString();
  return {
    ...obj,
    createdAt: obj.createdAt || now,
    updatedAt: now
  };
};

export const toPrettyDateTime = (d?: Date | string) => moment(d).format('lll');
export const toPrettyDate = (d?: Date | string) => moment(d).format('ll');
export const toMonthYear = (d?: Date | string) => moment(d).format('MMM, YY');
export const toSortableDate = (d?: Date | string | Moment) => moment(d).format('YYYY-MM-DD');
export const addDays = (d: Date | string | undefined, days: number) => moment(d).add(days, 'days').toDate();
