import {
  AgingReport,
  AgingReportBalance,
  AgingReportMessage,
  toMonthYear,
} from "common";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { Dropdown, Field, MultiCheckbox, TextArea } from "../components/form";
import { useUser } from "../store";
import { notify } from "../util/notify";
import { toPrettyCurrency } from "common";
import { BreadCrumbs } from "../components/BreadCrumbs";
import "./Report.scss";
import { ReportMessage } from "./ReportMessage";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { SlugLink } from "./SlugLink";
import { useReports } from "../store/reports";
import React from "react";

export const Report = () => {
  const { invokeApi, isAdmin, payer } = useUser();
  const { group, facility, residentName, billType, index } = useParams();
  const [report, setReport] = useStateWithCallbackLazy<AgingReport | null>(
    null
  );

  const { updateReport: updateReportState } = useReports();
  const [hideZeroBalances, setHideZeroBalances] = useState(true);
  const [showMessages, setShowMessages] = useState(true);
  const [users, setUsers] = useState([]);
  const latestMessageRef = useRef<null | HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    invokeApi({ endpoint: "/getUsersForNonAdmins" }).then((data: any) => {
      setUsers(data);
    });
  }, []);



  const refreshReport = () =>
    invokeApi({
      endpoint: "/getReport",
    }).then(
      (report: AgingReport) =>
        new Promise((resolve, reject) => {
          updateReportState(report);
          setReport(report, resolve);
        })
    );

  useEffect(() => {
    refreshReport();
  }, [residentName, billType, index]);

  const editMessage = (message: AgingReportMessage) => {
    Object.entries(message).forEach(([field, value]) => {
      setValue(field === "id" ? "messageId" : field, value);
    });
    setTimeout(() => {
      document
        .querySelector(".message-form")
        ?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const onSubmit = (data: any) => {
    if (!report) return;
    if (data.months && typeof data.months === "string") {
      data.months = [data.months];
    }
    if (!data.months) data.months = [];
    invokeApi({
      endpoint: "/putReportMessage",
      data: {
        ...data,
      },
    }).then((data: AgingReportMessage) => {
      refreshReport().then(() => {
        notify.success("Message added!");
        clearForm();
        document
          .querySelector(`.report-message[data-id="${data.id}"]`)
          ?.scrollIntoView({ behavior: "smooth" });
      });
    });
  };

  const clearForm = () => {
    reset();
    setValue("messageId", ""); // TODO HACK!!!!
  };

  const monthCheckboxOptions: any[] = [];
  if (report?.balances) {
    Object.entries(report.balances)?.forEach(([month, balance]) => {
      const { history, paid } = balance as AgingReportBalance;
      if (hideZeroBalances && history?.[0] === 0) return;
      monthCheckboxOptions.push({
        value: month,
        label: `${toMonthYear(month)}: ${history
          ?.map((value: number) =>
            toPrettyCurrency(value, { dashForZero: false })
          )
          .join(", ")}${paid ? " (PAID)" : ""}`,
      });
    });
  }

  if (!report) return null;
  return (
    <>
      <BreadCrumbs
        path={[
          group,
          facility,
          payer,
          [residentName, billType, index].join("/"),
        ]}
        labels={[
          "My Facilities",
          facility,
          payer,
          `${report?.residentName} - ${report.billType}`,
        ]}
      />

      <form className="content" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="title text-center">
          {report.residentName}'s ({report.billType}) Messages
        </h1>
        <hr />
        {report && (
          <>
            <h4 className="has-text-centered is-pulled-right">
              <i
                className={`pointer fa-solid ${showMessages ? "fa-caret-up" : "fa-caret-down"
                  }`}
                onClick={() => setShowMessages(!showMessages)}
              ></i>
            </h4>
            <br />

            <div className="report-messages-container">
              {showMessages &&
                report.messages?.map((message, index) => (
                  <React.Fragment key={message.id}>

                    <ReportMessage
                      key={message.id}
                      message={message}
                      report={report}
                      refreshReport={refreshReport}
                      editMessage={editMessage}
                      ref={
                        report.messages.length - 1 === index
                          ? latestMessageRef
                          : undefined
                      } />

                  </React.Fragment>
                ))}
            </div>

            {isAdmin && (
              <div className="box message-form">
                <label className="checkbox is-pulled-right">
                  <input
                    type="checkbox"
                    onChange={() => setHideZeroBalances(!hideZeroBalances)}
                  />{" "}
                  Show Zero Balances
                </label>
                <h3 className="title is-4 is-spaced ">
                  {watch("messageId") ? "Edit" : "Add"} Message
                </h3>
                <input type="hidden" {...register("messageId")} />
                <MultiCheckbox
                  name="months"
                  options={monthCheckboxOptions}
                  register={register}
                  errors={errors}
                  required={false}
                />
                <Field
                  name="deadline"
                  errors={errors}
                  register={register}
                  type="date"
                  required={false}
                  {...{
                    defaultValue: moment().endOf("month").format("YYYY-MM-DD"),
                    onFocus: (e: any) => e?.target?.showPicker(),
                    onClick: (e: any) => e?.target?.showPicker(),
                  }}
                />
                <Dropdown
                  required={false}
                  register={register}
                  errors={errors}
                  multiple
                  name="recipients"
                  options={users?.map((u: any) => u?.username)}
                />
                <TextArea register={register} errors={errors} name="message" />
                <hr />
                <div className="buttons is-pulled-left ">
                  <button
                    className="button is-warning "
                    onClick={() => clearForm()}
                  >
                    Clear
                  </button>
                  <SlugLink
                    path={[group, facility, payer]}
                    className="button is-link "
                  >
                    Go Back
                  </SlugLink>
                </div>
                <div className="buttons is-right is-pulled-right">
                  <button className="button is-primary ">Submit</button>
                </div>
                <div className="is-clearfix"></div>
              </div>
            )}
          </>
        )}
      </form>
    </>
  );
};
