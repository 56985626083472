import { AgingReport } from '../types';
import { toSlug } from './string';

export const addIndexToReports = (reports: AgingReport[]) => {
  const frequencies: Record<string, number> = {};
  return reports.map((r) => {
    const key = `${r.residentName}#${r.billType}`;
    frequencies[key] ??= 0;
    const rWithIndex = { ...r, index: frequencies[key] };
    frequencies[key] += 1;
    return rWithIndex;
  });
};

export const reportKey = (r: AgingReport) => `${r.residentName}#${r.billType}#${r.index}`.toLowerCase();

export const reportsToMap = (reports: AgingReport[]) => {
  const reportsWithIndexes = addIndexToReports(reports);
  const reportsMap: Record<string, AgingReport> = {};
  reportsWithIndexes.forEach((r) => {
    reportsMap[reportKey(r)] = r;
  });
  return reportsMap;
};
