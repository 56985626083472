import { atom, useAtom } from "jotai";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { notify } from "../util/notify";
import axios from "axios";
import { UserType, toSlug, AuthToken } from "common";
import { useEffect } from "react";

export const initialUserState = {
  username: "",
  email: "",
  group: "",
  type: UserType.Regular,
  facilities: [],
  authToken: "",
};

const loadedUserData = localStorage.getItem("userState")
  ? JSON.parse(localStorage.getItem("userState") as string)
  : initialUserState;

export const userState = atom(loadedUserData);

let refreshAuthTokenCalled = false;

export const useUser = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useAtom(userState);
  const { group, facility, payer, residentName, billType, index } = params;

  if (location.pathname === "/") {
    window.location.href = "/fairview-and-co";
  }

  useEffect(() => {
    if (
      user &&
      !refreshAuthTokenCalled &&
      !location.pathname.includes("login") &&
      !location.pathname.includes("reset-password")
    ) {
      refreshAuthTokenCalled = true;
      invokeApi({
        endpoint: "/refreshAuthToken",
        callingRefreshAuthToken: true,
      });
    }
  }, []);

  const invokeApi = async ({
    endpoint,
    data,
    callingRefreshAuthToken,
  }: any) => {
    const notifyId = notify.loading("Loading...");

    try {
      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + endpoint,
        data: {
          authToken: user?.authToken,
          group,
          facility,
          payer,
          residentName,
          billType,
          index: index !== undefined ? Number(index) : index,
          ...data,
        },
      });
      if (response.data.authToken) {
        setUser({ ...user, authToken: response.data.authToken });
      }

      return response.data;
    } catch (error: any) {
      if (callingRefreshAuthToken) {
        const next = location.pathname.includes("login")
          ? `/${params.group}`
          : location.pathname;
        navigate(`/${toSlug(params.group)}/login?next=${next}`);
        return;
      }

      console.error(error?.response?.data?.description || error);
      console.error(error?.response?.data?.message);

      if (error.response.data.description === "Custom Error") {
        notify.error(error?.response?.data?.message, { duration: 5000 });
      } else if (error.response.data.description === "Authentication Error") {
        notify.error("Please log in");
        navigate(`/${toSlug(params.group)}/login?next=${location.pathname}`);
      } else if (error.response.data.description === "Validation Error") {
        const validationError = JSON.parse(error.response.data.message);
        notify.error(
          validationError
            .map(
              ({ instancePath, message }: any) =>
                `${instancePath.substring(1)}: ${message}`
            )
            .join("<br/>")
        );
      } else {
        notify.error(
          error?.response?.data?.description || "Oops, there was an issue."
        );
      }
      throw error;
    } finally {
      notify.dismiss(notifyId);
    }
  };

  const login = async (username: string, password: string) => {
    const response = await invokeApi({
      endpoint: "/authenticate",
      data: {
        username,
        password,
        group: params.group,
      },
    });
    setUser(response);
    localStorage.setItem("userState", JSON.stringify(response));
    notify.success("You have successfully logged in!");
    if (location.pathname.includes("login")) {
      navigate(searchParams.get("next") || `/${toSlug(response.group)}`);
    }
  };

  const resetPassword = async (token: string, password: string) => {
    const response = await invokeApi({
      endpoint: "/resetPassword",
      data: { token, password },
    });
    setUser(response);
    localStorage.setItem("userState", JSON.stringify(response));
    notify.success("You have successfully logged in!");
    navigate(`/${toSlug(response.group)}`);
  };

  const logout = () => {
    notify.success("You have successfully logged out");
    navigate(`/${toSlug(user.group)}/login`);
    setUser(initialUserState);
    localStorage.removeItem("userState");
  };

  return {
    login,
    logout,
    resetPassword,
    invokeApi,
    setUser,
    group,
    facility,
    payer: payer?.toUpperCase(),
    authenticated: user?.username,
    isAdmin: user?.type === UserType.Admin,
    user,
    navigate,
    ...user,
  };
};
