import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { Dropdown, Field } from "../components/form";
import { useUser } from "../store";
import { usersState } from "../store/users";
import { notify } from "../util/notify";
import { UserType } from "common";

const UserForm = ({
  handleSubmit,
  onSubmit,
  errors,
  register,
  users,
  disableUsername,
}: any) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="username"
        errors={errors}
        register={register}
        disabled={disableUsername}
      />
      <Field name="name" errors={errors} register={register} />
      <Field name="email" errors={errors} register={register} />
      {users?.facilities && (
        <Dropdown
          name="facilities"
          errors={errors}
          register={register}
          options={users?.facilities}
          multiple={true}
          selected={users?.facilities}
        />
      )}

      <Dropdown
        name="type"
        errors={errors}
        register={register}
        options={Object.keys(UserType)}
        selected={"Regular"}
      />
      <hr />
      <div className="form-actions">
        <button className="button is-primary ">Submit</button>
        <Link to=".." className="button is-link ">
          Cancel
        </Link>
        <div className="is-clearfix"></div>
      </div>
    </form>
  );
};

const EditUserForm = ({ user, ...rest }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: user });
  return (
    <UserForm
      {...{ register, handleSubmit, errors, disableUsername: true, ...rest }}
    />
  );
};

const CreateUserForm = ({ ...rest }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  return <UserForm {...{ register, handleSubmit, errors, ...rest }} />;
};

export const EditUser = () => {
  const { invokeApi, group } = useUser();
  const params = useParams();
  const navigate = useNavigate();

  const [users, setUsers] = useAtom(usersState);

  useEffect(() => {
    invokeApi({ endpoint: "/getUsers" }).then((users: any) => {
      setUsers(users);
    });
  }, []);

  const isCreateMode = Boolean(!params.username);
  const user: any =
    !isCreateMode &&
    users.users.find(({ username }: any) => username === params.username);

  const onSubmit = (data: any) => {
    invokeApi({
      endpoint: isCreateMode ? "/createUser" : "/updateUser",
      data,
    }).then(() => {
      if (isCreateMode) {
        notify.success("User successfully created!");
      } else {
        notify.success("User successfully updated!");
      }
      navigate(`/${group}/users`);
    });
  };

  return (
    <div className="content">
      <h1 className="title text-center">
        {params.username ? `Edit User: ${params.username}` : "Create User"}
      </h1>
      <hr />
      {!isCreateMode && user ? (
        <EditUserForm user={user} users={users} onSubmit={onSubmit} />
      ) : (
        <CreateUserForm users={users} onSubmit={onSubmit} />
      )}
    </div>
  );
};
