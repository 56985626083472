export const removeFromArray = (array: any[], element: any) => {
  const index = array.indexOf(element);
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};

export function* chunk<T>(array: T[], chunkSize: number) {
  for (let i = 0; i < array.length; i += chunkSize) {
    yield array.slice(i, i + chunkSize);
  }
}

export const promiseAllBatched = async (promises: Promise<any>[], batchSize: number) => {
  for (const c of chunk(promises, batchSize)) {
    await Promise.all(c);
  }
};

export const flatten = <T>(arrays: T[][]) => ([] as T[]).concat.apply([], arrays);

export const lastItem = <T>(array: T[]): T | undefined => {
  if (!array?.length) return undefined;
  return array[array.length - 1];
};
