import {
  AgingReport,
  Month,
  toMonthYear,
  toPrettyCurrency,
  toSlug,
} from "common";
import { SlugLink } from "./SlugLink";
import { useParams } from "react-router-dom";
import { useUser } from "../store";
import { notify } from "../util/notify";
import { BalanceCell } from "./BalanceCell";
import { AgingReportStatusesByMonth, AgingReportWithStatusesByMonth } from "../util/getAgingReportStatus";

export const ReportRow = ({
  report,
  months,
  updateReport,
  statusFilters,
}: {
  report: AgingReportWithStatusesByMonth;
  months: Month[];
  statusFilters: any;
  updateReport: (report: AgingReport) => {};
}) => {
  const { group, facility, payer } = useParams();
  const { invokeApi } = useUser();

  const toggleClaimPaid = async ({
    month,
    report,
  }: {
    month: Month;
    report: AgingReport;
  }) => {
    invokeApi({
      endpoint: "/toggleClaimPaid",
      data: {
        month,
        residentName: toSlug(report.residentName),
        billType: toSlug(report.billType),
        index: report.index,
      },
    }).then((report: AgingReport) => {
      updateReport(report);
      notify.success(
        `Toggled paid status for: ${report.residentName} (${toMonthYear(
          month
        )})`
      );
    });
  };

  return (
    <tr key={report.sk}>
      <td>
        <SlugLink
          path={[
            group,
            facility,
            payer,
            report.residentName,
            report.billType,
            report.index,
          ]}
        >
          {report.residentName}
        </SlugLink>
      </td>
      <td>{report.billType}</td>
      {months?.map((month) => {
        return (
          <BalanceCell
            statusFilters={statusFilters}
            key={`${report.sk}-${month}`}
            balance={report?.balances?.[month]}
            statusesMap={report?.statusesByMonth?.[month]}
            toggleClaimPaid={() => toggleClaimPaid({ month, report })}
          />
        );
      })}
      <td>{toPrettyCurrency(report.prior)}</td>
      <td>{toPrettyCurrency(report.net)}</td>
      <td>{report.medicaidApplication}</td>
      <td>{report.agingStatus}</td>
    </tr>
  );
};
