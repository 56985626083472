import {
  AgingReportBalanceStatusData,
  AgingReportBalanceStatusType,
} from "../util/getAgingReportStatus";
import { titlecase } from "stringcase";

export const StatusIcon = ({
  statusType,
  statusData,
}: {
  statusType: AgingReportBalanceStatusType;
  statusData?: AgingReportBalanceStatusData;
}) => {
  const statusClasses: Record<string, string> = {
    paid: "fa-solid fa-dollar-sign",
    changed: "fa-solid fa-question",
    outstanding: "fa-solid fa-money-check-dollar",
    "deadline-passed": "fa-solid fa-triangle-exclamation",
    "extended-deadline-passed": "fa-solid fa-triangle-exclamation",
    "message-provided": "fa-solid fa-comment",
    "response-provided": "fa-solid fa-comments",
  };

  let iArgs: Record<string, string> = {
    "className": `${statusClasses[statusType]} tooltip-anchor`,
    "data-tooltip-place": "bottom",
    "data-tooltip-content": titlecase(statusType)
  };
  const isMessageType =
    AgingReportBalanceStatusType.MessageProvided === statusType;
  if (isMessageType && statusData?.messages?.length) {
    iArgs = {
      "className": `${statusClasses[statusType]} tooltip-anchor`,
      "data-tooltip-place": "bottom",
      "data-tooltip-html": statusData?.messages.join('<br/>'),
    };
  }
  return (
    <>
      <i  {...iArgs}></i>
      {isMessageType && <span className="badge">{statusData?.count}</span>}
    </>
  );
};
