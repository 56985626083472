export enum UserType {
  Regular = 'Regular',
  Admin = 'Admin'
}

export interface AuthToken {
  email: string;
  name: string;
  username: string;
  group: string;
  type: UserType;
  facilities: string[];
}

export type AuthenticatedUser = AuthToken;
