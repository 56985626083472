import {
  AgingReportMessage,
  toPrettyDateTime,
  toPrettyDate,
  toMonthYear,
  AgingReportMessageResponse,
  AgingReport,
  getAgingReportMessageDeadline,
} from "common";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useUser } from "../store";
import { notify } from "../util/notify";
import { Field, TextArea } from "./form";
import { useState } from "react";

export const ReportMessage = ({
  report,
  message,
  refreshReport,
  editMessage,
  ref
}: {
  report: AgingReport;
  message: AgingReportMessage;
  refreshReport: () => {};
  editMessage: (message: AgingReportMessage) => void;
  ref: any;
}) => {
  const { author, recipients, months, responses, createdAt, id } = message;
  const { isAdmin, invokeApi } = useUser();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const deleteReportMessage = () => {
    if (window.confirm("Are you sure you want to delete this message?")) {
      invokeApi({
        endpoint: "/deleteReportMessage",
        data: {
          messageId: id,
        },
      }).then(() => {
        notify.success("Message deleted!");
        refreshReport();
      });
    }
  };

  const deleteResponse = (response: AgingReportMessageResponse) => {
    if (window.confirm("Are you sure you want to delete this response?")) {
      invokeApi({
        endpoint: "/deleteReportMessageResponse",
        data: {
          messageId: id,
          responseId: response.id,
        },
      }).then(() => {
        notify.success("Response deleted!");
        refreshReport();
      });
    }
  };

  const addReportMessageResponse = ({ response, deadline }: any) => {
    invokeApi({
      endpoint: "/addReportMessageResponse",
      data: {

        messageId: id,
        response,
        deadline,
      },
    }).then(() => {
      notify.success("Response saved!");
      refreshReport();
      reset();
    });
  };
  const deadline = getAgingReportMessageDeadline(message);
  return (
    <article className="box no-select media report-message" data-id={message.id} ref={ref}>

      <div className="media-content overflow-inherit">
        <div className="content">
          <strong>{author}</strong> <i>@ {toPrettyDateTime(createdAt)}</i>{" "}
          {deadline ? (
            <div>
              <strong>
                Deadline: {toPrettyDate(deadline)}
              </strong>
            </div>
          ) : null}
          {months?.length ? (
            <div>Months: {months?.map(toMonthYear)?.join(" &  ")}</div>
          ) : null}
          {recipients?.length ? (
            <div>Recipients: {recipients?.join(", ")}</div>
          ) : null}
          <div>Message: {message.message}</div>
          {responses?.map((response: AgingReportMessageResponse) => (
            <div key={response.id}>
              <div>
                <strong>{response.author}</strong>{" "}
                <i> ({toPrettyDate(response.createdAt)}</i>) :{" "}
                {response.message}{" "}
                {response?.deadline && (
                  <span>
                    -{" "}
                    <strong>Deadline: {toPrettyDate(response.deadline)}</strong>
                  </span>
                )}{" "}
                -{" "}
                <span
                  className=" is-cursor has-text-danger"
                  title="delete"
                  onClick={() => deleteResponse(response)}
                >
                  delete
                </span>
              </div>
            </div>
          ))}
          <hr />
          <div className="control">
            <TextArea
              register={register}
              errors={errors}
              placeholder="Add a response"
              name="response"
            />
            <Field
              name="deadline"
              errors={errors}
              register={register}
              type="date"
              required={false}
              {...{
                onFocus: (e: any) => e?.target?.showPicker(),
                onClick: (e: any) => e?.target?.showPicker(),
              }}
            />
          </div>
          <br />
          {isAdmin && (
            <div className="buttons has-addons pull-left">

              <span
                className="button is-primary cursor"
                onClick={(e) => { e.persist(); editMessage(message) }}
              >
                <span className="icon is-small">
                  <i className="fa-solid fa-pen"></i>
                </span>
              </span>
              <span
                className="button is-danger cursor"
                onClick={() => deleteReportMessage()}
              >
                <span className="icon is-small">
                  <i className="fa-solid fa-trash"></i>
                </span>
              </span>
            </div>
          )}
          <button
            className="button is-info pull-right"
            type="button"
            onClick={handleSubmit(addReportMessageResponse)}
          >
            <span className="icon is-small">
              <i className="fa-solid fa-plus"></i>
            </span>
            &nbsp; Add Response
          </button>
          <div className="is-clear-fix" />
        </div>
      </div>
    </article >
  );
};
