import classNames from "classnames";
import "./form.scss";

export const Dropdown = ({
  name,
  errors,
  register,
  options,
  multiple,
  selected,
  required = true,
}: {
  name: string;
  errors: any;
  register: any;
  options: string[];
  multiple?: boolean;
  selected?: string | string[];
  required?: boolean;
}) => (
  <div className="field">
    <label className="label capitalize">{name}</label>
    <div className="control">
      <div
        className={classNames("select is-fullwidth", {
          "is-multiple": multiple,
        })}
      >
        <select
          multiple={multiple}
          defaultValue={selected}
          {...register(name, required && { required: `${name} is required.` })}
        >
          {!multiple && <option />}
          {options &&
            options.map((option) => (
              <option key={option} value={option} className="capitalize">
                {option}
              </option>
            ))
          }
        </select>
      </div>
    </div>
    <p className="help is-danger">{errors?.[name]?.message}</p>
  </div>
);

export const MultiCheckbox = ({
  name,
  errors,
  register,
  options,
  required = true,
}: {
  name: string;
  errors: any;
  register: any;
  options: any[];
  required?: boolean;
}) => (
  <div className="field">
    <label className="label">{name}</label>
    <div className="control">
      {options?.map(({ value, label }: any) => {
        return (
          <div key={label}>
            <input
              type="checkbox"
              {...register(
                name,
                required && { required: `${name} is required.` }
              )}
              value={value}
            />
            &nbsp;{label}
            <br />
          </div>
        );
      })}
    </div>
    <p className="help is-danger">{errors?.[name]?.message}</p>
  </div>
);
