import classNames from "classnames";
import "./BreadCrumbs.scss";
import { SlugLink } from "./SlugLink";

export const BreadCrumbs = ({
  path,
  labels,
}: {
  path: string[];
  labels?: string[];
}) => {
  return (
    <>
      <div className="is-pulled-left position-absolute breadcrumb-container">
        <nav className="breadcrumb" aria-label="breadcrumbs">
          <ul>
            {path.map((part, index) => (
              <li
                className={classNames({
                  "is-active": index === path.length - 1,
                })}
                key={part}
              >
                <SlugLink path={path.slice(0, index+1)} className="is-capitalized">
                  {labels?.[index] || part}
                </SlugLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="spacer"></div>
    </>
  );
};
