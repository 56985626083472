import classNames from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../store";
import { toSlug } from "common";
import "./Nav.scss";

export default function Nav() {
  const [burgerClicked, setBurgerClicked] = useState(false);
  const { user, authenticated, logout, group } = useUser();

  return (
    <nav className="navbar is-link has-shadow is-unselectable">
      <div className="navbar-brand">
        <Link to={group ? `/${toSlug(group)}` : "#"} className="navbar-item">
          <img
            alt="icon"
            src={`${process.env.PUBLIC_URL}/icon.png`}
            className="icon"
          />
          &nbsp;
          {process.env.REACT_APP_APP_NAME}
        </Link>
        <div
          onClick={() => setBurgerClicked(!burgerClicked)}
          className={classNames("navbar-burger burger", {
            "is-active": burgerClicked,
          })}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {authenticated && (
        <div
          className={classNames("navbar-menu", { "is-active": burgerClicked })}
          onClick={() => setBurgerClicked(!burgerClicked)}
        >
          <div className="navbar-start">
            <div className="navbar-item has-dropdown is-hoverable">
              <Link to={`/${toSlug(user.group)}/`} className="navbar-link">
                Facilities
              </Link>
              <div className="navbar-dropdown is-boxed">
                {user?.facilities?.map((facility: string, index: number) => (
                  <span key={facility}>
                    {user?.facilitiesPayers?.[facility]?.map((payer: string) => (
                      <Link
                        to={`/${toSlug(user.group)}/${toSlug(
                          facility
                        )}/${toSlug(payer)}`}
                        className="navbar-item"
                        key={`${facility}${payer}`}
                      >
                        <span className="capitalize">{facility}</span> - <span className="uppercase">{payer}</span>
                      </Link>
                    ))}
                    {index !== user.facilities.length - 1 && (
                      <hr className="dropdown-divider" />
                    )}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-item navbar-link has-pointer">
                {user?.username}
              </div>
              <div className="navbar-dropdown is-boxed is-right">
                {user.type === "Admin" && (
                  <div>
                    <Link to="users" className="navbar-item">
                      Users
                    </Link>
                    <hr className="dropdown-divider" />
                  </div>
                )}
                <div
                  className="navbar-item logout-link has-pointer"
                  onClick={logout}
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
