import { toPrettyDateTime } from "common";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { BreadCrumbs } from "../components/BreadCrumbs";
import { useUser } from "../store";
import { usersState } from "../store/users";
import { notify } from "../util/notify";
import { useAtom } from 'jotai'

export default function Users() {
  const { invokeApi, user: currentUser, group } = useUser();
  const [users, setUsers] = useAtom(usersState);

  const refreshUsers = () => {
    invokeApi({ endpoint: "/getUsers" }).then((users) => {
      setUsers(users);
    });
  };
  useEffect(refreshUsers, []);

  const deleteUser = (user) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to delete ${user.username}?`)) {
      invokeApi({
        endpoint: "/deleteUser",
        data: { username: user.username },
      }).then((users) => {
        notify.success("successfully deleted user");
        refreshUsers();
      });
    }
  };

  const issuePasswordReset = (user) => {
    invokeApi({
      endpoint: "/issuePasswordReset",
      data: { username: user.username },
    }).then(() => notify.success("Issue Reset Email Sent!"));
  };

  return (
    <>
      <BreadCrumbs
        path={[group, "users"]}
        labels={["My Facilities"]}
      />

      <div className="content">
        <h1 className="title is-centered">Users</h1>
        <hr />
        <div className="actions">
          <Link to="create" className="button is-success">
            Create User
          </Link>
        </div>
        <table className="is-clearfix table is-bordered is-striped is-narrow  is-hoverable is-fullwidth has-text-centered is-size-7">
          <thead>
            <tr>
              <th>Username</th>
              <th>Name</th>
              <th>Email</th>
              <th>Facilities</th>
              <th>Type</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users?.users?.map((user) => {
              const {
                createdAt,
                email,
                facilities,
                name,
                type,
                updatedAt,
                username,
              } = user;
              return (
                <tr key={email}>
                  <td>{username}</td>
                  <td>{name}</td>
                  <td>{email}</td>
                  <td class="capitalize">{facilities.join(", ")}</td>
                  <td>{type}</td>
                  <td>{toPrettyDateTime(createdAt)}</td>
                  <td>{toPrettyDateTime(updatedAt)}</td>
                  <td className="">
                    <Link to={username} className="has-text-info">
                      edit
                    </Link>

                    {user.username !== currentUser.username && (
                      <>
                        <div
                          className="has-text-danger pointer"
                          onClick={() => deleteUser(user)}
                        >
                          delete
                        </div>
                        <div
                          className="has-text-primary pointer"
                          onClick={() => issuePasswordReset(user)}
                        >
                          issue password reset
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
