import { lastItem } from './../logic/array';
import { WithTrackingDates } from './date';

export interface AgingReportBalance {
  history: number[];
  paid?: boolean;
}

export interface AgingReportMessageResponse extends WithTrackingDates{
  id: string;
  message: string;
  author: string;
  deadline?: string;
}
export type Month = string;
export interface AgingReportMessage extends WithTrackingDates {
  id: string;
  author: string;
  deadline: string;
  message: string;
  responses: AgingReportMessageResponse[];
  months: Month[];
  recipients: string[];
}

// AgingReport partition
export interface AgingReportPK {
  group: string;
  facility: string;
  payer: string;
}

// Individual AgingReport id
export interface AgingReportSK {
  residentName: string;
  billType: string;
  index: number;
}


export interface AgingReport extends WithTrackingDates {
  pk: string;
  sk: string;
  residentName: string;
  billType: string;
  medicaidApplication: string;
  agingStatus: string;
  balances: Record<Month, AgingReportBalance>;
  prior: number;
  net: number;
  messages: AgingReportMessage[];
  index?: number;
}



export const getAgingReportMessageDeadline = (message: AgingReportMessage) => {
  if (!message.responses?.length) {
    return message.deadline;
  }

  return lastItem(message.responses)?.deadline;
};



