import { useState, useRef, useEffect } from "react";
import { useUser } from "../store";
import "./FacilityDashboard.scss";
import { notify } from "../util/notify";
import { toPrettyCurrency, toMonthYear } from "common";
import { useReports } from "../store/reports";
import { BreadCrumbs } from "./BreadCrumbs";
import _ from "lodash";
import { StatusIcon } from "./StatusIcon";
import { SlugLink } from "./SlugLink";
import { ReportRow } from "./ReportRow";
import { AgingReportBalanceStatusType } from "../util/getAgingReportStatus";

export default function FacilityDashboard() {
  const { invokeApi, user, group, facility, payer } = useUser();
  const tableRef = useRef(null);
  const {
    reports,
    months,
    displayableReports,
    totals,
    setSearchTerm,
    statusFilters,
    setStatusFilters,
    updateReport,
  } = useReports();
  const [file, setFile] = useState();

  const uploadReport = async (e) => {
    e.preventDefault();
    if (file) {
      await invokeApi({
        endpoint: "/uploadReport",
        data: { file },
      }).then(() => {
        notify.success(
          "Successfully uploaded report - please check back shortly.",
          { duration: 5000 }
        );
        setFile(null);
      });
    }
  };

  const onFileSelected = (event) => {
    var file = event.target.files[0];
    const { name, type } = file;
    if (file) {
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function (evt) {
        setFile({ name, type, content: evt.target.result });
      };
    }
  };

  const StatusCheckbox = ({ label, status }) => {
    return (
      <label className={`is-${status} checkbox`}>
        <input
          type="checkbox"
          checked={statusFilters.includes(status)}
          onChange={(e) => {
            if (e.target.checked) {
              setStatusFilters([...statusFilters, status]);
            } else {
              setStatusFilters(_.without(statusFilters, status));
            }
          }}
        />{" "}
        {label} <StatusIcon statusType={status} />
      </label>
    );
  };

  const onTableScroll = (e) => {
    localStorage.setItem(`${facility}#${payer}#scrollTop`, e.target.scrollTop);
  };

  useEffect(() => {
    if (tableRef.current)
      tableRef.current.scrollTop =
        localStorage.getItem(`${facility}#${payer}#scrollTop`) || 0;
  }, [displayableReports]);

  return (
    <>
      <BreadCrumbs
        path={[group, facility, payer]}
        labels={["My Facilities", facility, payer]}
      />

      <div className="content">
        <h1 className="title is-capitalized">
          {facility}-{payer} {reports.length ? `(${reports.length})` : null}
        </h1>
        <hr />

        <div className="actions">
          <div className="field has-addons is-pulled-left">
            <div className="control has-icons-left has-icons-right">
              <input
                className="input"
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-magnifying-glass"></i>
              </span>
            </div>
          </div>

          {user.type === "Admin" && (
            <div className="field is-pulled-right has-addons">
              <div className="control">
                <label className="file-label ">
                  <input
                    className="file-input"
                    type="file"
                    onChange={onFileSelected}
                  />
                  <span className="file-name">
                    <i className="fas fa-upload"></i> &nbsp;
                    {file?.name || "No File Chosen"}
                  </span>
                </label>
              </div>
              <div className="control">
                <button className="button is-success" onClick={uploadReport}>
                  {" "}
                  Upload Report{" "}
                </button>
              </div>
              <div className="control">
                <SlugLink
                  path={[group, facility, payer, "backups"]}
                  className="button is-info ml-3"
                >
                  Backups
                </SlugLink>
              </div>
            </div>
          )}
        </div>
        <div className="is-clearfix">
          {!reports.length ? (
            <div className="text-center">
              No aging reports found for this facility.
              <br /> Please upload an aging report to start tracking.
            </div>
          ) : (
            <div
              className="table-container no-wrap"
              ref={tableRef}
              onScroll={onTableScroll}
            >
              <div className="status-key">
                <span className="key-specifier">Key:</span>
                <StatusCheckbox
                  label="Paid"
                  status={AgingReportBalanceStatusType.Paid}
                />
                <StatusCheckbox
                  label="Changed"
                  status={AgingReportBalanceStatusType.Changed}
                />
                <StatusCheckbox
                  label="Outstanding"
                  status={AgingReportBalanceStatusType.Outstanding}
                />
                <StatusCheckbox
                  label="Deadline Passed"
                  status={AgingReportBalanceStatusType.DeadlinePassed}
                />
                <StatusCheckbox
                  label="Extended Deadline Passed"
                  status={AgingReportBalanceStatusType.ExtendedDeadlinePassed}
                />
                <StatusCheckbox
                  label="Message Provided"
                  status={AgingReportBalanceStatusType.MessageProvided}
                />
              </div>
              <table className="is-size-7 is-clearfix table is-bordered is-striped is-narrow is-hoverable is-fullwidth has-text-centered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Bill Type</th>
                    {months?.map((month) => (
                      <th key={month}>{toMonthYear(month)}</th>
                    ))}

                    <th>Prior</th>
                    <th>Net</th>
                    <th>Medicaid Application</th>
                    <th>Aging Status</th>
                  </tr>
                </thead>
                <tbody>
                  {displayableReports?.map((report) => (
                    <ReportRow
                      report={report}
                      statusFilters={statusFilters}
                      key={report.pk + report.sk}
                      months={months}
                      updateReport={updateReport}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th>Total: {displayableReports.length}</th>
                    <th></th>

                    {months?.map((month) => (
                      <th key={month}>{toPrettyCurrency(totals[month])}</th>
                    ))}
                    <th>{toPrettyCurrency(toPrettyCurrency(totals.prior))}</th>
                    <th>{toPrettyCurrency(toPrettyCurrency(totals.net))}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
