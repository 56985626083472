import classNames from "classnames";
import { useForm } from "react-hook-form";
import { useUser } from "../store";

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { login } = useUser();

  const onSubmit = async ({ username, password }) => {
    login(username, password);
  };

  return (
    <form className="section is-medium" onSubmit={handleSubmit(onSubmit)}>
      <div className="container">
        <h1 className="title">Login</h1>
        <div className="field">
          <label className="label">Username</label>
          <div className="control has-icons-left">
            <input
              {...register("username", { required: "Username is required" })}
              className={classNames("input", {
                "is-danger": errors?.username,
              })}
              type="text"
              placeholder="Username"
            />
            <span className="icon is-small is-left">
              <i className="fas fa-user"></i>
            </span>
          </div>
          <p className="help is-danger">{errors?.username?.message}</p>
        </div>
        <div className="field">
          <label className="label">Password</label>
          <div className="control has-icons-left">
            <input
              {...register("password", { required: "Password is required" })}
              className={classNames("input", {
                "is-danger": errors?.password,
              })}
              type="password"
              placeholder="Password"
            />
            <span className="icon is-small is-left">
              <i className="fas fa-lock"></i>
            </span>
          </div>
          <p className="help is-danger">{errors?.password?.message}</p>
        </div>
        <div className="field">
          <div className="control">
            <button className="button is-link">Submit</button>
          </div>
        </div>
      </div>
    </form>
  );
}
