import { Link } from "react-router-dom";
import { useUser } from "../store";
import { toSlug } from "common";
import { BreadCrumbs } from "./BreadCrumbs";

export const Payers = () => {
  const { user, group, facility } = useUser();
  return (
    <>
      <BreadCrumbs
        path={[group, facility]}
        labels={["My Facilities", facility]}
      />
      <br />
      <br />
      <nav className="panel">
        <p className="panel-heading">My Payers</p>
        {user.facilitiesPayers[facility].map((payer) => (
          <Link
            key={payer}
            to={`/${toSlug(user.group)}/${toSlug(facility)}/${toSlug(payer)}`}
            className="panel-block uppercase"
          >
            <span className="panel-icon">
              <i className="fas fa-home"></i>
            </span>
            {payer}
          </Link>
        ))}
      </nav>
    </>
  );
};
