import { Routes, Route } from "react-router-dom";
import App from "./App";
import { NotFoundPage } from "./components/404";
import FacilityDashboard from "./components/FacilityDashboard";
import { EditUser } from "./components/EditUser";
import { Facilities } from "./components/Facilities";
import Login from "./components/login";
import { Report } from "./components/Report";
import ResetPassword from "./components/resetPassword";
import Backups from "./components/Backups";
import Users from "./components/users";
import { useUser } from "./store";
import { Payers } from "./components/payers";

export const AppRoutes = () => {
  const { authenticated, user } = useUser();

  return (
    <Routes>
      <Route path="/:group" element={<App />}>
        <Route path="login" element={<Login />} />
        {!authenticated ? (
          <>
            <Route path="reset-password/:token" element={<ResetPassword />} />
            <Route path="" element={<Login />} />
            <Route path="*" element={<Login />} />
          </>
        ) : (
          <>
            {user.type === "Admin" && (
              <>
                <Route path="users" element={<Users />} />
                <Route path="users/create" element={<EditUser />} />
                <Route path="users/:username" element={<EditUser />} />
              </>
            )}
            <Route path=":facility" element={<Payers />} />
            <Route path=":facility/:payer/backups" element={<Backups />} />
            <Route path=":facility/:payer/:residentName/:billType/:index" element={<Report />} />
            <Route path=":facility/:payer" element={<FacilityDashboard />} />
            <Route path="" element={<Facilities />} />
            <Route path="*" element={<NotFoundPage />} />
          </>
        )}
      </Route>
      <Route path="" element={<NotFoundPage />} />
    </Routes>
  );
};
