import { Link } from "react-router-dom";
import { useUser } from "../store";
import { toSlug } from "common";

export const Facilities = () => {
  const { user } = useUser();
  return (
    <>
      <nav className="panel">
        <p className="panel-heading">My Facilities</p>
        {user.facilities.map((facility) => (
          <Link
            key={facility}
            to={`/${toSlug(user.group)}/${toSlug(facility)}`}
            className="panel-block capitalize"
          >
            <span className="panel-icon">
              <i className="fas fa-home"></i>
            </span>
            {facility}
          </Link>
        ))}
      </nav>
    </>
  );
};
