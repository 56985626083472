import classNames from "classnames";
import { useEffect, useState } from "react";
import { useUser } from "../store";
import { notify } from "../util/notify";
import { BreadCrumbs } from "./BreadCrumbs";

export default function Backups() {
  const { invokeApi, group, facility, payer } = useUser();
  const [backupsData, setBackupsData] = useState();

  const getBackups = () =>
    invokeApi({
      endpoint: "/getBackups",
      data: { facility: facility },
    }).then(setBackupsData);

  useEffect(() => {
    getBackups();
  }, [facility]);

  const downloadFile = ({ sk }) => {
    invokeApi({
      endpoint: "/downloadUploadedReport",
      data: { backupSk: sk },
    }).then((data) => {
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = `data:${data.contentType};base64,${data.content}`;
      downloadLink.target = "_self";
      downloadLink.download = data.filename;
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  const restoreBackup = (backup) => {
    if (
      window.confirm(
        `Are you sure you want to restore ${facility}-${payer} to ${backup.filename}?`
      )
    ) {
      invokeApi({
        endpoint: "/restoreBackup",
        data: { backupSk: backup.sk },
      }).then(() => {
        notify.success("Backup has been restored successfully!");
        getBackups();
      });
    }
  };

  const { active, backups } = backupsData || {};

  return (
    <>
      <BreadCrumbs
        path={[group, facility, payer, "backups"]}
        labels={["My Facilities", facility, payer, "backups"]}
      />
      <div className="content">
        <h1 className="title">Backups</h1>
        <hr />

        <table className="is-clearfix table is-bordered is-striped is-narrow is-hoverable is-fullwidth has-text-centered is-size-7">
          <thead>
            <tr>
              <th>Name</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {backups?.map((backup) => (
              <tr key={backup.sk}>
                <td>
                  <a
                    className="is-link is-light"
                    onClick={() => downloadFile(backup)}
                  >
                    &nbsp;
                    {active?.keys?.sk === backup.sk && (
                      <i className="fa-solid fa-chevron-right"></i>
                    )}{" "}
                    &nbsp;
                    <i className="fa-solid fa-download"></i>&nbsp;
                    <span
                      className={classNames({
                        "is-bold has-text-success":
                          active?.keys?.sk === backup.sk,
                      })}
                    >
                      {backup.filename}
                    </span>
                  </a>
                </td>
                <td>{backup.sk}</td>
                <td onClick={() => restoreBackup(backup)}>
                  {" "}
                  <i class="fa-solid fa-clock-rotate-left"></i> <a>restore</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
