import classNames from "classnames";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useUser } from "../store";

export default function ResetPassword() {
  const params = useParams();

  const { register, handleSubmit, formState, watch } = useForm();
  const { errors } = formState;
  const { resetPassword } = useUser();

  const onSubmit = async ({ password }) =>
    resetPassword(params.token, password);

  return (
    <form className="section is-medium" onSubmit={handleSubmit(onSubmit)}>
      <div className="container">
        <h1 className="title">Reset Password</h1>

        <div className="field">
          <label className="label">Password</label>
          <div className="control has-icons-left">
            <input
              {...register("password", { required: "Password is required" })}
              className={classNames("input", { "is-danger": errors?.password })}
              type="password"
              placeholder="Password"
            />
            <span className="icon is-small is-left">
              <i className="fas fa-user"></i>
            </span>
          </div>
          <p className="help is-danger">{errors?.password?.message}</p>
        </div>

        <div className="field">
          <label className="label">Confirm</label>
          <div className="control has-icons-left">
            <input
              {...register("confirm", {
                required: true,
                validate: (val) => val === watch("password"),
              })}
              className={classNames("input", {
                "is-danger": errors?.confirm,
              })}
              type="password"
              placeholder="Confirm"
            />
            <span className="icon is-small is-left">
              <i className="fas fa-lock"></i>
            </span>
          </div>
          <p className="help is-danger">
            {errors?.confirm && "You must confirm your password"}
          </p>
        </div>
        <div className="field">
          <div className="control">
            <button className="button is-link">Submit</button>
          </div>
        </div>
      </div>
    </form>
  );
}
