import { AgingReportMessage } from "common";
import { AgingReportMessageResponse } from "common";
import { AgingReportBalance, AgingReport, Month } from "common";
import moment from "moment";

export enum AgingReportBalanceStatusType {
  Paid = "paid",
  Changed = "changed",
  Outstanding = "outstanding",
  DeadlinePassed = "deadline-passed",
  ExtendedDeadlinePassed = "extended-deadline-passed",
  MessageProvided = "message-provided",
}

/** 
 * Use Cases:
 * 1. Display statuses on dashboard with counts and messages
 * 2. Filter by status
 * 3. Show only selected status
 * 
 *  Report:
 *   statusesByMonth: 
 *       Record<Month, Record<AgingReportBalanceStatusType, AgingReportBalanceStatusData>>; 
  */
export interface AgingReportBalanceStatusData {
  count: number;
  messages?: string[];
}
export type AgingReportStatusesMap = Record<AgingReportBalanceStatusType, AgingReportBalanceStatusData>;
export type AgingReportStatusesByMonth = Record<
  Month,
  AgingReportStatusesMap
>;

export interface AgingReportWithStatusesByMonth extends AgingReport {
  statusesByMonth?: AgingReportStatusesByMonth;
}

export const getAgingReportStatuses = (
  report: AgingReport
): AgingReportStatusesByMonth => {

  const reportStatuses: AgingReportStatusesByMonth = {};
  Object.entries(report.balances).forEach(
    ([month, { history, paid }]: [Month, AgingReportBalance]) => {
      reportStatuses[month] = {} as AgingReportStatusesMap;

      const addStatus = (status: AgingReportBalanceStatusType, message?: string) => {
        reportStatuses[month][status] ??= { count: 0, messages: [] };
        reportStatuses[month][status].count += 1;
        if (message) {
          reportStatuses[month][status].messages!.push(message);
        }
      }

      const value = history?.[0];
      if (!value || value < 0) return;
      if (paid) addStatus(AgingReportBalanceStatusType.Paid);
      if (history?.length === 1)
        addStatus(AgingReportBalanceStatusType.Outstanding);

      const addDeadlineStatuses = (deadline: string) => {
        const today = moment().startOf("day");
        if (today.isSameOrAfter(moment(deadline).add(3, "days"))) {
          addStatus(AgingReportBalanceStatusType.ExtendedDeadlinePassed);
        } else if (today.isSameOrAfter(moment(deadline))) {
          addStatus(AgingReportBalanceStatusType.DeadlinePassed);
        }
      };
      report.messages?.forEach((message: AgingReportMessage) => {
        if (message?.months?.includes(month)) {
          addStatus(AgingReportBalanceStatusType.MessageProvided, `Message: ${message.author}: ${message.message}`);
          if (message.responses?.length) {
            message.responses.forEach(
              (response: AgingReportMessageResponse, index: number) => {
                addStatus(AgingReportBalanceStatusType.MessageProvided, `Response: ${response.author}: ${response.message}`);
                if (
                  index === message.responses.length - 1 &&
                  response.deadline
                ) {
                  addDeadlineStatuses(response.deadline);
                }
              }
            );
          } else {
            addDeadlineStatuses(message.deadline);
          }
        }
      });

      if (history?.[0] - history?.[1]) {
        addStatus(AgingReportBalanceStatusType.Changed);
      }

    }
  );

  return reportStatuses;
};
