import {
  Outlet,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "./App.scss";
import Nav from "./components/Nav";
import { Toaster } from "react-hot-toast";
import { ErrorBoundary } from "react-error-boundary";
import { notify } from "./util/notify";
import { useEffect } from "react";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from "react-tooltip";



function ErrorFallback({ error, resetErrorBoundary }: any) {
  console.log("error", error);
  const params = useParams();
  return (
    <div className="section">
      <div className="not-found-page container is-widescreen">
        <div className="title">Oops, something went wrong.</div>
        If the issue persists, please contact your admin. &nbsp;
        <a
          href={`/${params.group}${
            params.facility ? `/${params.facility}` : ""
          }`}
        >
          Go Back
        </a>
      </div>
    </div>
  );
}

function App() {
  const currentYear = new Date().getFullYear();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("error")) {
      notify.error(searchParams.get("error"));
      searchParams.delete("error");
      setSearchParams(searchParams);
    }
  }, []);
  return (
    <div id="main-root" className="container is-fluid">
      <div className="wrapper">
        <Nav />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Outlet />
        </ErrorBoundary>
        <Toaster />
        <Tooltip anchorSelect=".tooltip-anchor" />
      </div>
      <footer id="main-footer">
        {process.env.REACT_APP_APP_NAME} &copy; {currentYear}
      </footer>
    </div>
  );
}

export default App;
