import { AgingReport, Month } from "common";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { atomWithStorage } from 'jotai/utils'


import { useUser } from "./user";
import { produce } from "immer";
import {
  AgingReportBalanceStatusType,
  AgingReportWithStatusesByMonth,
  getAgingReportStatuses,
} from "../util/getAgingReportStatus";
import { flatten } from "lodash";



export interface ReportsState {
  reports: AgingReportWithStatusesByMonth[];
  totals: Record<Month, number>;
  months: Month[];
}

type FacilityPayer = string;
export const reportsPerFacilityPayerState = atomWithStorage(
  'reportsPerFacilityPayerState',
  {} as Record<FacilityPayer, ReportsState>
);

export const useReports = () => {
  const { invokeApi, facility, payer } = useUser();
  const [reportsPerFacilityPayer, setReportsPerFacilityPayer] = useAtom(
    reportsPerFacilityPayerState
  );
  const facilityPayer: FacilityPayer = `${facility}#${payer}`;
  const reports = reportsPerFacilityPayer?.[facilityPayer]?.reports || [];
  const [displayableReports, setDisplayableReports] = useState(reports);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [statusFilters, setStatusFilters] = useState<
    AgingReportBalanceStatusType[]
  >([]);

  const calculateAndSetTotals = (reports: AgingReport[]) => {
    const calculatedTotals: Record<Month, number> = {};
    reports.forEach((report) => {
      for (const month in report.balances) {
        calculatedTotals[month] ??= 0;
        const balance = report.balances[month];
        const amount = balance?.history?.[0];
        calculatedTotals[month] += amount;
      }
      calculatedTotals.net ??= 0;
      calculatedTotals.prior ??= 0;

      calculatedTotals.net += report.net;
      calculatedTotals.prior += report.prior;
    });
    return calculatedTotals;
  };

  const getMonthsFromReports = (reports: AgingReport[]) =>
    Object.keys(reports?.[0]?.balances || {})
      .map((month) => month)
      .sort()
      .reverse();

  /**
   * On facility change:
   *   1. fetch reports
   *   2. setMonthsFromReports
   *   3. set totals
   */
  useEffect(() => {
    // if (!reportsPerFacilityPayer?.[facilityPayer]?.reports?.length) {
      invokeApi({ endpoint: "/getReports" }).then((reports: AgingReport[]) => {
        reports = reports.map((report) => ({
          ...report,
          statusesByMonth: getAgingReportStatuses(report),
        })) as AgingReportWithStatusesByMonth[];
        setReportsPerFacilityPayer(
          produce(reportsPerFacilityPayer, (draft) => {
            draft[facilityPayer] = {
              reports,
              months: getMonthsFromReports(reports),
              totals: calculateAndSetTotals(reports),
            };
          })
        );
      });
    // }
  }, [facilityPayer]);

  useEffect(() => {
    const trimmedSearchTerm = searchTerm?.trim();

    if (!trimmedSearchTerm && !statusFilters.length) {
      setDisplayableReports(reports);
      return;
    }

    let filteredReports = reports;
    if (statusFilters?.length) {
      filteredReports = filteredReports.filter((r) => {
        if (r.statusesByMonth) {
          const allStatuses = new Set(
            flatten(
              Object.values(r.statusesByMonth).map((statusesByMonth) => Object.keys(statusesByMonth))
            )
          );
          return statusFilters.every((sf) => allStatuses.has(sf));
        }
        return false;
      });
    }

    if (trimmedSearchTerm) {
      filteredReports = filteredReports.filter(
        (report: AgingReport) =>
          trimmedSearchTerm &&
          JSON.stringify(report)
            .toLowerCase()
            .includes(trimmedSearchTerm.toLowerCase())
      );
    }

    if (filteredReports.length !== reports.length) {
      setReportsPerFacilityPayer(
        produce(reportsPerFacilityPayer, (draft) => {
          draft[facilityPayer].totals = calculateAndSetTotals(filteredReports);
        })
      );
      setDisplayableReports(filteredReports);
    }
  }, [reports, searchTerm, statusFilters]);

  const updateReport = (report: AgingReport) => {
    if(!reports?.length) return;
    let matchingIndex: number;
    setReportsPerFacilityPayer(
      produce(reportsPerFacilityPayer, (draft) => {
        matchingIndex = draft?.[facilityPayer].reports.findIndex(
          (r) => r.pk === report.pk && r.sk === report.sk
        );
        if (matchingIndex !== -1) {
          draft[facilityPayer].reports[matchingIndex] = {
            ...report,
            statusesByMonth: getAgingReportStatuses(report),
          };
        }
      })
    );
  };

  return {
    reports,
    displayableReports,
    totals: reportsPerFacilityPayer?.[facilityPayer]?.totals,
    months: reportsPerFacilityPayer?.[facilityPayer]?.months,
    setSearchTerm,
    statusFilters,
    setStatusFilters,
    updateReport,
  };
};
