import classNames from "classnames";
import "./form.scss";

export const Field = ({
  name,
  errors,
  register,
  disabled,
  type = "text",
  required = true,
  ...rest
}: {
  name: string;
  errors: any;
  register: any;
  required?: boolean;
  disabled?: boolean;
  type?: string;
}) => (
  <div className="field">
    <label className="label capitalize">{name}</label>
    <div className="control">
      <input
        {...register(
          name,
          required && { required: `<span className="capitalize">${name}</span> is required.` }
        )}
        className={classNames("input", {
          "is-danger": errors?.[name],
        })}
        type={type}
        placeholder={name}
        disabled={disabled}
        {...rest}
      />
    </div>
    <p className="help is-danger">{errors?.[name]?.message}</p>
  </div>
);

export const TextArea = ({
  register,
  name,
  errors,
  placeholder,
}: {
  register: any;
  name: string;
  errors: any;
  placeholder?: string;
}) => (
  <div className="field">
    <label className="label capitalize">{name}</label>
    <div className="control">
      <textarea
        {...register(name, { required: `${name} is required.` })}
        className={classNames("textarea", {
          "is-danger": errors?.[name],
        })}
        placeholder={placeholder || name}
      />
    </div>
    <p className="help is-danger">{errors?.[name]?.message}</p>
  </div>
);
